<template lang="pug">
.sidebar-input-wrapper.flex-row(style="align-items: flex-end" v-if="showSetting")
  label.switch-label {{ $t(label) }}
  .d-flex.flex-row.flex-0(v-if="!templateEdit")
    om-button.ml-2(
      v-if="!isFeedback && !isPickAPresent"
      style="min-width: 0rem; padding: unset"
      ghost
      aria-hidden="true"
      @click="setProperty('textWeight')"
      :class="{ selected: textWeight === true }"
    )
      template
        UilBold(size="2rem")
    om-button.ml-2(
      style="min-width: 0rem; padding: unset"
      ghost
      aria-hidden="true"
      @click="setProperty('fontItalic')"
      :class="{ selected: fontItalic === true }"
    )
      template
        UilItalic(size="2rem")
    om-button.ml-2(
      style="min-width: 0rem; padding: unset"
      ghost
      aria-hidden="true"
      @click="setProperty('textDecoration')"
      :class="{ selected: textDecoration === true }"
    )
      template
        UilUnderline(size="2rem")
    om-button.ml-2(
      v-if="strikeThrough"
      style="min-width: 0rem; padding: unset"
      ghost
      aria-hidden="true"
      @click="setProperty('textStrikeThrough')"
      :class="{ selected: textStrikeThrough === true }"
    )
      template
        UilTextStrikeThrough(size="2rem")
  .flex-row.flex-0(v-else)
    om-button.ml-2(
      v-if="showItems.includes('textWeight')"
      aria-hidden="true"
      @click="setProperty('textWeight')"
      :class="{ 'is-active': textWeight === true }"
    )
      template
        UilBold(size="2rem")
    om-button.ml-2(
      v-if="showItems.includes('fontItalic')"
      aria-hidden="true"
      @click="setProperty('fontItalic')"
      :class="{ 'is-active': fontItalic === true }"
    )
      template
        UilItalic(size="2rem")
    om-button.ml-2(
      v-if="showItems.includes('textDecoration')"
      aria-hidden="true"
      @click="setProperty('textDecoration')"
      :class="{ 'is-active': textDecoration === true }"
    )
      template
        UilUnderline(size="2rem")
    om-button.ml-2(
      v-if="showItems.includes('textStrikeThrough')"
      aria-hidden="true"
      @click="setProperty('textStrikeThrough')"
      :class="{ 'is-active': textStrikeThrough === true }"
    )
      template
        UilTextStrikeThrough(size="2rem")
</template>
<script>
  import { mapMutations } from 'vuex';
  import { UilBold, UilItalic, UilUnderline, UilTextStrikeThrough } from '@iconscout/vue-unicons';
  import itemMixin from '../../../mixins/item';

  export default {
    components: {
      UilBold,
      UilItalic,
      UilUnderline,
      UilTextStrikeThrough,
    },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      subPath: { type: String, default: 'style' },
      typeOfComponent: { type: String, default: 'global' },
      strikeThrough: { type: Boolean, default: false },
      showItems: { type: Array, default: () => [] },
      templateEdit: { type: Boolean, default: false },
    },
    computed: {
      showSetting() {
        return !this.templateEdit || (this.templateEdit && this.showItems.length);
      },
      fontItalic: {
        get() {
          return this.getValueOf(this.getPath('fontItalic'));
        },
        set(value) {
          this.setValueOf(this.getPath('fontItalic'), value);
        },
      },
      textWeight: {
        get() {
          return this.getValueOf(this.getPath('textWeight'));
        },
        set(value) {
          this.setValueOf(this.getPath('textWeight'), value);
        },
      },
      textDecoration: {
        get() {
          return this.getValueOf(this.getPath('textDecoration'));
        },
        set(value) {
          this.setValueOf(this.getPath('textDecoration'), value);
        },
      },
      textStrikeThrough: {
        get() {
          return this.getValueOf(this.getPath('textStrikeThrough'));
        },
        set(value) {
          this.setValueOf(this.getPath('textStrikeThrough'), value);
        },
      },
      isFeedback() {
        return ['input-picker', 'feedback'].includes(this.typeOfComponent);
      },
      isPickAPresent() {
        return this.element && this.element.type === 'OmPickAPresent';
      },
    },
    methods: {
      ...mapMutations(['updateStyle']),
      setProperty(property) {
        this[property] = !this[property];
      },
    },
  };
</script>
