import Notification from './components/Notification.vue';
import { events } from './events';

const OmToast = {
  install(Vue, args = {}) {
    if (this.installed) {
      return;
    }

    this.installed = true;
    this.params = args;

    Vue.component(args.componentName || 'om-notification', Notification);

    const OmNotification = (params) => {
      if (typeof params === 'string') {
        params = { title: '', text: params };
      }

      if (typeof params === 'object') {
        events.$emit('add', params);
      }
    };

    OmNotification.close = function (id) {
      events.$emit('close', id);
    };

    const name = args.name || 'OmNotification';

    Vue.prototype[`$${name}`] = OmNotification;
    Vue[name] = OmNotification;
  },
};

export default OmToast;
