<template lang="pug">
div
  template(v-if="isComponentEnabledForSketchV2")
    om-color-input-v2(
      :property="property"
      :label="label"
      :typeOfComponent="typeOfComponent"
      :alpha="alpha"
      :gradient="gradient"
      :onlySolid="onlySolid"
      :colorInstance="colorInstance"
    )
  template(v-else)
    om-color-input-old(
      :property="property"
      :label="label"
      :typeOfComponent="typeOfComponent"
      :alpha="alpha"
      :gradient="gradient"
      :onlySolid="onlySolid"
    )
</template>
<script>
  import { mapState } from 'vuex';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import OmColorInputV2 from './OmColorInputV2.vue';
  import OmColorInputOld from './OmColorInput.vue';
  import ColorComponentsFactory from '../../../utils/color-components/components';

  export default {
    components: {
      OmColorInputV2,
      OmColorInputOld,
    },
    mixins: [themeColorsMixin],
    props: {
      property: { type: String, required: true },
      label: { type: String, required: true },
      typeOfComponent: { type: String, default: 'global' },
      alpha: { type: Boolean, default: true },
      gradient: { type: Object, required: false },
      onlySolid: { type: Boolean, default: false },
    },
    data() {
      return {
        componentsForV2: [
          'global',
          'row',
          'column',
          'button',
          'product',
          'text',
          'coupon',
          'feedback',
          'inputs',
          'scratchCard',
          'survey',
          'social',
          'input-picker',
          'luckyWheel',
          'pickapresent',
          'countdown',
          'divider',
        ],
        colorInstance: null,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'globalStyle', 'mobilePreview']),
      isComponentEnabledForSketchV2() {
        return this.componentsForV2.includes(this.typeOfComponent);
      },

      isGlobalStyle() {
        return this.property.includes('globalStyle');
      },
    },

    watch: {
      mobilePreview() {
        this.createColorInstance();
      },
    },
    created() {
      this.createColorInstance();

      this.$bus.$on('historyChanged', this.rebuildColorInstance);
      this.$bus.$on('rebuild-color-instance', this.rebuildColorInstance);
      this.$bus.$on('updateValueInColorInstance', this.updateValueInColorInstance);
    },
    methods: {
      rebuildColorInstance() {
        if (!this.colorInstance) return;
        this.$nextTick(() => {
          this.colorInstance = null;
          this.createColorInstance();
        });
      },
      updateValueInColorInstance({ property, value }) {
        if (!this.colorInstance) return;

        if (property.includes('background.linearDirection')) {
          this.colorInstance.setLinearDirection(value);
          this.$store.commit('handleColorInstanceToStore', this.colorInstance);
        }
      },
      createColorInstance() {
        if (!this.isComponentEnabledForSketchV2) return;

        /*
        somewhere in the code we pass whole path with selectedElement but for the SketchV2 is not necessary
        */
        let replacedPath = this.property;
        if (replacedPath.includes('selectedElement')) {
          replacedPath = replacedPath.replace('selectedElement.', '');
        }

        /*
          somewhere in the code we pass the property with $device so we need to replace it,
          after all components migrated for V2 we must pass the correct path
        */
        if (replacedPath.includes('$device')) {
          replacedPath = replacedPath.replace('$device', 'desktop');
        }

        this.colorInstance = new ColorComponentsFactory(
          this.typeOfComponent,
          this.isGlobalStyle ? this.globalStyle : this.selectedElement,
          replacedPath,
          this.getColorsMethod(),
          this.isGlobalStyle,
        );
      },
    },
  };
</script>
