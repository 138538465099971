import './public-path';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import { apolloProvider, apolloClient } from '@/apollo';
import { registerEditorComponents } from '@/componentUtil';
import App from '@/App.vue';
import CodeViewSvg from '@/editor/components/svg/CodeViewSvg';
import DesktopSvg from '@/editor/components/svg/DesktopSvg';
import MobileSvg from '@/editor/components/svg/MobileSvg';
import i18n from '@/i18n';
import Notification from 'vue-notification';
import OmTable from '@/components/OmTable';
import OmLogoSvg from '@/assets//admin/svg/OmLogoSvg';
import FacebookSvg from '@/assets//admin/svg/FacebookSvg';
import GoogleSvg from '@/assets//admin/svg/GoogleSvg';
import CalendarSvg from '@/assets//admin/svg/CalendarSvg';
import MapSvg from '@/assets//admin/svg/MapSvg';
import PayPalSvg from '@/assets//admin/svg/PayPalSvg';
import CreditCardSvg from '@/assets//admin/svg/CreditCardSvg';
import ShopifyPaySvg from '@/assets//admin/svg/ShopifyPaySvg';
import BillingAddressSvg from '@/assets//admin/svg/BillingAddressSvg';
import PaymentMethodSvg from '@/assets//admin/svg/PaymentMethodSvg';
import OrderSummarySvg from '@/assets//admin/svg/OrderSummarySvg';
import InfoIconSvg from '@/assets//admin/svg/InfoIconSvg';
import OmFavicon from '@/assets/om-favicon.png';
import WLFavicon from '@/assets/cog-wheel.png';
import Alert from '@/components/Alert';
import LogRocket from 'logrocket';
import './plugin';
import router from '@/router/index';
import store from '@/store';
import Vuelidate from 'vuelidate';
import VueGtm from 'vue-gtm';
import VueScrollTo from 'vue-scrollto';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { restClient } from '@/axios';
import VTooltip from 'v-tooltip';
import VueSwitches from 'vue-switches';
import 'vue-range-slider/dist/vue-range-slider.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import '@/globalComponents';
// eslint-disable-next-line
import 'moment/locale/hu.js';
import LoadingLogo from '@/components/Svg/LoadingLogo.vue';
import * as Cookie from 'js-cookie';
import { getWLSettings } from '@/mixins/whiteLabelling';
import WHITELABEL_SETTINGS_FOR_DOMAIN from '@/graphql/GetAgencyWhiteLabelSettingsForDomain.gql';
import { insertHubSpotScript } from '@/services/hubSpot';
import { isWhitelabelDomain } from './config/url';
import runtimeConfig from './config/runtime';
import './utils/shopify';
import OmToastService from './NotificationService/OmToast';

registerEditorComponents(Vue);

// register our toast notification service
Vue.use(OmToastService);
// -
Vue.prototype.$axios = restClient;
Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.component('VueSwitches', VueSwitches);
Vue.component('CodeViewSvg', CodeViewSvg);
Vue.component('DesktopSvg', DesktopSvg);
Vue.component('MobileSvg', MobileSvg);
Vue.component('OmTable', OmTable);
Vue.component('OmLogoSvg', OmLogoSvg);
Vue.component('FacebookSvg', FacebookSvg);
Vue.component('GoogleSvg', GoogleSvg);
Vue.component('CalendarSvg', CalendarSvg);
Vue.component('LoadingLogo', LoadingLogo);
Vue.component('MapSvg', MapSvg);
Vue.component('PayPalSvg', PayPalSvg);
Vue.component('CreditCardSvg', CreditCardSvg);
Vue.component('ShopifyPaySvg', ShopifyPaySvg);
Vue.component('BillingAddressSvg', BillingAddressSvg);
Vue.component('PaymentMethodSvg', PaymentMethodSvg);
Vue.component('OrderSummarySvg', OrderSummarySvg);
Vue.component('InfoIconSvg', InfoIconSvg);
Vue.component('Alert', Alert);
Vue.use(Notification);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(PortalVue);
Vue.config.productionTip = false;
const bus = new Vue();
Vue.prototype.$bus = bus;
window.om = { bus, store, i18n };

const init = async () => {
  store.commit('autoLogin');
  await store.dispatch('autoLogin', false);
  Vue.use(VueGtm, {
    id: runtimeConfig.VUE_APP_TAG_MANAGER_ID, // Your GTM ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
  });

  window.dataLayer = window.dataLayer || [];
  if (Cookie.get('referrer')) {
    window.dataLayer.push({ referrer: Cookie.get('referrer') });
  }

  Vue.filter('thousandSep', (value, sep) => {
    if (value === undefined || value === null) {
      return '';
    }
    // prettier-ignore
    return sep
      ? value.toLocaleString('en').split(',').join(sep)
      : value.toLocaleString(store.state.locale);
  });

  Vue.filter('removeProtocol', (value) => {
    if (value === undefined || value === null) {
      return '';
    }
    return value.replace(/(^\w+:|^)\/\//, '');
  });

  Vue.filter('cutString', (str, maxLength) => {
    if (str && str.length > maxLength) {
      return str.substr(0, maxLength).concat('...');
    }

    return str;
  });

  Vue.filter('dec2', (x) => `${Number.parseFloat(x).toFixed(2)} %`);

  const appConfig = {
    store,
    router,
    i18n,
    apolloProvider,
    render: (h) => h(App),
  };

  const _changeFavicon = (newIcon) => {
    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = newIcon;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  };
  const app = new Vue(appConfig).$mount('#app');

  let whiteLabelSettings = false;

  const isLogin = /\/login/gi.test(location.href);
  const isRegister = /\/register/gi.test(location.href);

  const logRocketProject = runtimeConfig.VUE_APP_LOGROCKET_PROJECT;
  const logRocketInited = window._logRocketInited;
  if (logRocketProject && !logRocketInited) {
    LogRocket.init(logRocketProject, { mergeIframes: true });
    window._logRocketInited = true;
  }

  if (whiteLabelSettings === false && apolloClient && isWhitelabelDomain()) {
    getWLSettings(apolloClient, WHITELABEL_SETTINGS_FOR_DOMAIN)
      .then(({ data }) => {
        whiteLabelSettings = data.settings;

        if (whiteLabelSettings) {
          document.title = app.$t('WL.title', { brand: whiteLabelSettings.brandName });

          if (isLogin) {
            document.title = app.$t('WL.loginTitle', { brand: whiteLabelSettings.brandName });
          } else if (isRegister) {
            document.title = app.$t('WL.registerTitle', { brand: whiteLabelSettings.brandName });
          }
        }

        if (whiteLabelSettings.customSquared) {
          _changeFavicon(whiteLabelSettings.customSquared);
        } else if (isWhitelabelDomain()) {
          _changeFavicon(WLFavicon);
        } else {
          _changeFavicon(OmFavicon);
        }
      })
      .catch(() => {
        whiteLabelSettings = null;
      });
  } else if (!isWhitelabelDomain() && (isLogin || isRegister)) {
    if (isLogin) {
      document.title = app.$t('titles.login');
    } else if (isRegister) {
      document.title = app.$t('titles.register');
    }
  }

  insertHubSpotScript();
};

init();
