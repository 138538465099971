<template lang="pug">
sidebar-tab-content#style(ref="content")
  slot(name="before")
  slot
    OmBackgroundBox(:propsProperty="property" :typeOfComponent="component" :editMobile="mobile")
    om-border-box(:propsProperty="property" :typeOfComponent="component" :editMobile="true")
    sidebar-accordion(
      v-if="isHeightResizerEnabled"
      :addDeviceSelector="false"
      :opened="true"
      :title="$t('size')"
      ref="accordion"
    )
      om-dropdown-input(
        v-if="isFullscreenRow"
        label="height"
        property="selectedElement.heightType"
        :items="heightModes"
      )
      template(v-if="!isFullscreenRow || selectedElement.desktop.heightType === 'manual'")
        .sidebar.min-height-input-wrapper
          label {{ $t('minHeight') }}
          .d-flex.min-height-input
            om-simple-input(:property="minHeightProperty")
            span.unit px
  slot(name="after")
</template>

<script>
  import predefinedTabMixin from '@/editor/mixins/sidebar/predefinedTab';
  import { mapGetters, mapState } from 'vuex';
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper';

  export default {
    mixins: [predefinedTabMixin],
    data: () => ({
      heightModes: [
        { key: 'h100', value: '100h' },
        { key: 'manual', value: 'manual' },
      ],
    }),
    computed: {
      ...mapState(['mobilePreview', 'selectedElement', 'hasWheel']),
      ...mapGetters(['hasAccountFeature', 'isFullscreen']),
      isHeightResizerEnabled() {
        if (this.hasWheel) return false;

        if (this.mobilePreview && this.selectedElement.type === ELEMENTS.OmCol) return true;

        if (!this.mobilePreview && this.selectedElement.type === ELEMENTS.OmRow) return true;

        return false;
      },
      device() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
      minHeightProperty() {
        return `selectedElement.${this.device}.minHeight`;
      },
      isFullscreenRow() {
        return this.selectedElement.type === 'OmRow' && this.isFullscreen;
      },
    },
  };
</script>
